import createLabelStyles from '../label/style-loader';

export default function createStyles(config, className = '.select-container.select-container') {
  const {
    font,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight,
    background_color: backgroundColor,
    background_color_focus: backgroundColorFocus,
    divider_color: dividerColor,
    width,
    height,
    margin_top: marginTop,
    margin_bottom: marginBottom,
    margin_left: marginLeft,
    margin_right: marginRight,
    padding_top: paddingTop,
    padding_bottom: paddingBottom,
    padding_left: paddingLeft,
    padding_right: paddingRight,
    border_color: borderColor,
    border_thickness: borderThickness,
    border_radius: borderRadius,
    label,
    error,
    placeholder,
  } = config || {};

  const {
    font: placeholderFont,
    font_size: placeholderFontSize,
    font_color: placeholderFontColor,
    font_weight: placeholderFontWeight,
  } = placeholder || {};

  const {
    border_color: errorBorderColor,
    border_thickness: errorBorderThickness,
    border_radius: errorBorderRadius,
  } = error;

  const styles = [];
  createLabelStyles(label, `${className} > .select-label`).forEach((style) => {
    styles.push(style);
  });

  createLabelStyles(error, `${className} > .select-helper-text`).forEach((style) => {
    styles.push(style);
  });

  let rules = '';
  if (font?.length > 0) {
    rules += `font-family:${font};`;
  }
  if (fontSize?.length > 0) {
    rules += `font-size:${fontSize};`;
  }
  if (fontColor?.length > 0) {
    rules += `color:${fontColor};`;
  }
  if (fontWeight?.length > 0) {
    rules += `font-weight:${fontWeight};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} > .select-box > .select-box-selected {${rules}}`);
    styles.push(
      `${className} > .select-dropdown-container > .select-dropdown > .select-item-container > .select-item {${rules}}`
    );
  }
  if (backgroundColorFocus) {
    styles.push(
      `${className} > .select-dropdown-container > .select-dropdown > .select-item-container:hover {background-color:${backgroundColorFocus};}`
    );
    styles.push(
      `${className} > .select-dropdown-container > .select-dropdown > .select-item-container.selected {background-color:${backgroundColorFocus};}`
    );
  }

  rules = '';

  if (width?.length > 0) {
    rules += `width:${width};`;
  }
  if (height?.length > 0) {
    rules += `height:${height};`;
  }
  if (marginTop?.length > 0) {
    rules += `margin-top:${marginTop};`;
  }
  if (marginBottom?.length > 0) {
    rules += `margin-bottom:${marginBottom};`;
  }
  if (marginLeft?.length > 0) {
    rules += `margin-left:${marginLeft};`;
  }
  if (marginRight?.length > 0) {
    rules += `margin-right:${marginRight};`;
  }

  if (paddingTop?.length > 0) {
    rules += `padding-top:${paddingTop};`;
  }
  if (paddingBottom?.length > 0) {
    rules += `padding-bottom:${paddingBottom};`;
  }
  if (paddingLeft?.length > 0) {
    rules += `padding-left:${paddingLeft};`;
  }
  if (paddingRight?.length > 0) {
    rules += `padding-right:${paddingRight};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .select-box {${rules}}`);
    styles.push(
      `${className} > .select-dropdown-container > .select-dropdown > .select-item-container {${rules}}`
    );
  }

  rules = '';

  if (borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
    rules += `border-style:solid;`;
  }
  if (borderThickness?.length > 0) {
    rules += `border-width:${borderThickness};`;
    rules += `border-style:solid;`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
    rules += `border-style:solid;`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .select-box.select-box {${rules}}`);
  }

  rules = '';

  if (borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (borderThickness?.length > 0) {
    rules += `border-width:${borderThickness};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (borderRadius) {
    rules += `border-bottom-left-radius:${borderRadius};`;
    rules += `border-bottom-right-radius:${borderRadius};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (rules.length > 0) {
    styles.push(`${className}  > .select-dropdown-container > .select-dropdown {${rules}}`);
  }
  rules = '';
  rules += `border-bottom-left-radius:0;`;
  rules += `border-bottom-right-radius:0;`;
  styles.push(`${className}.open > .select-box.select-box {${rules}}`);

  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (dividerColor?.length > 0) {
    rules += `border-bottom-color:${dividerColor};`;
  }

  if (rules.length > 0) {
    styles.push(
      `${className} > .select-dropdown-container > .select-dropdown > .select-item-container {${rules}}`
    );
  }
  rules = '';

  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .select-box {${rules}}`);
    styles.push(`${className} > .select-dropdown-container > .select-dropdown {${rules}}`);
  }

  rules = '';
  if (placeholderFont?.length > 0) {
    rules += `font-family:${placeholderFont};`;
  }
  if (placeholderFontSize?.length > 0) {
    rules += `font-size:${placeholderFontSize};`;
  }
  if (placeholderFontColor?.length > 0) {
    rules += `color:${placeholderFontColor};`;
  }
  if (placeholderFontWeight?.length > 0) {
    rules += `font-weight:${placeholderFontWeight};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .select-box > .select-box-placeholder {${rules}}`);
  }

  rules = '';
  if (errorBorderColor?.length > 0) {
    rules += `border-color:${errorBorderColor};`;
    rules += `border-style:solid;`;
  }
  if (errorBorderThickness?.length > 0) {
    rules += `border-width:${errorBorderThickness};`;
    rules += `border-style:solid;`;
  }
  if (errorBorderRadius) {
    rules += `border-radius:${errorBorderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`${className}.error > .select-box.select-box {${rules}}`);
  }
  rules = '';
  if (errorBorderColor?.length > 0) {
    rules += `border-color:${errorBorderColor};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (errorBorderThickness?.length > 0) {
    rules += `border-width:${errorBorderThickness};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (errorBorderRadius) {
    rules += `border-bottom-left-radius:${errorBorderRadius};`;
    rules += `border-bottom-right-radius:${errorBorderRadius};`;
    rules += `border-left-style:solid;`;
    rules += `border-right-style:solid;`;
    rules += `border-bottom-style:solid;`;
  }
  if (rules.length > 0) {
    styles.push(`${className}.error  > .select-dropdown-container > .select-dropdown {${rules}}`);
  }

  return styles;
}
