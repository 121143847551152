import createLabelStyles from '../label/style-loader';

export default function createStyles(config, className = '.checkbox-container') {
  const {
    label,
    background_color: backgroundColor,
    border_color: borderColor,
    border_thickness: borderThickness,
    border_radius: borderRadius,
    checked,
    error,
  } = config || {};

  const {
    border_color: errorBorderColor,
    border_thickness: errorBorderThickness,
    border_radius: errorBorderRadius,
  } = error;

  const styles = [];
  createLabelStyles(label, `${className} > .checkbox-inner-container > .checkbox-text`).forEach(
    (style) => {
      styles.push(style);
    }
  );
  createLabelStyles(error, `${className}.error > .checkbox-helper-text`).forEach((style) => {
    styles.push(style);
  });
  let containerRules = '';
  if (backgroundColor?.length > 0) {
    containerRules += `background-color:${backgroundColor};`;
  }
  if (borderColor?.length > 0) {
    containerRules += `border-color:${borderColor};`;
  }
  if (borderThickness?.length > 0) {
    containerRules += `border-width:${borderThickness};`;
  }
  if (borderRadius) {
    containerRules += `border-radius:${borderRadius};`;
  }
  if (containerRules.length > 0) {
    styles.push(`${className}> .checkbox-inner-container > .checkbox-field {${containerRules}}`);
  }
  if (checked) {
    const {
      background_color: checkedBackgroundColor,
      border_color: checkedBorderColor,
      color: checkedColor,
    } = checked;
    let checkedRules = '';
    if (checkedBackgroundColor?.length > 0) {
      checkedRules += `background-color:${checkedBackgroundColor};`;
    }
    if (checkedBorderColor?.length > 0) {
      checkedRules += `border-color:${checkedBorderColor};`;
    }
    if (checkedRules.length > 0) {
      styles.push(
        `${className} > .checkbox-inner-container > .checkbox-field.checked {${checkedRules}}`
      );
    }

    if (checkedColor?.length > 0) {
      styles.push(
        `${className} > .checkbox-inner-container > .checkbox-field > .checkbox-tick.checkbox-tick {color:${checkedColor};}`
      );
    }
  }

  let rules = '';
  if (errorBorderColor?.length > 0) {
    rules += `border-color:${errorBorderColor};`;
  }
  if (errorBorderThickness?.length > 0) {
    rules += `border-width:${errorBorderThickness};`;
  }
  if (errorBorderRadius) {
    rules += `border-radius:${errorBorderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(
      `${className}.error > .checkbox-inner-container > .checkbox-field.checkbox-field {${rules}}`
    );
  }

  return styles;
}
