export default function createStyles(config, className = '.facebook-button.facebook-button') {
  const {
    font,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight,
    background_color: backgroundColor,
    border_color: borderColor,
    border_thickness: borderThickness,
    border_radius: borderRadius,
    padding_top: paddingTop,
    padding_bottom: paddingBottom,
  } = config || {};

  const styles = [];

  let rules = '';
  if (font?.length > 0) {
    rules += `font-family:${font};`;
  }
  if (fontSize?.length > 0) {
    rules += `font-size:${fontSize};`;
  }
  if (fontColor?.length > 0) {
    rules += `color:${fontColor};`;
  }
  if (fontWeight?.length > 0) {
    rules += `font-weight:${fontWeight};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} > .facebook-button-label {${rules}}`);
  }

  rules = '';

  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderThickness?.length > 0) {
    rules += `border-width:${borderThickness};`;
  }
  if (borderRadius?.length > 0) {
    rules += `border-radius:${borderRadius};`;
  }
  if (paddingTop?.length > 0) {
    rules += `padding-top:${paddingTop};`;
  }
  if (paddingBottom?.length > 0) {
    rules += `padding-bottom:${paddingBottom};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} {${rules}}`);
  }

  return styles;
}
