//
//

import React from 'react';
import loadable from '@loadable/component';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ComponentLoader(loaderProps) {
  const { load, props, onComplete } = loaderProps;

  const Loader = loadable(load, {
    fallback: (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    ),
    resolveComponent: (component) => {
      if (onComplete) {
        onComplete();
      }
      // eslint-disable-next-line no-underscore-dangle
      if (component.__esModule) {
        return component.default;
      }
      return component;
    },
  });
  return <Loader {...props} />;
}
