/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import getAllUrlParams from '../../util/get-url-params';
import ComponentLoader from '../../components/component-loader';
import App from '../../app';

const removeLoader = () => {
  window.removeLoader(300);
};

const ClaimPage = (props) => {
  const { match, location, config } = props || {};
  const { params } = match;
  let { hash } = location;
  if (hash.startsWith('#/')) {
    hash = hash.replace('#/', '');
  } else if (hash.startsWith('#')) {
    hash = hash.replace('#', '');
  }
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('../../features/claim')}
      props={{
        ...params,
        ...searchParams,
        config,
        hash,
        removeLoader,
      }}
    />
  );
};

ReactDOM.render(
  <App path="./">
    {(config) => {
      return (
        <Router>
          <Switch>
            {config && <Route key="claim" component={(props) => ClaimPage({ ...props, config })} />}
          </Switch>
        </Router>
      );
    }}
  </App>,
  document.getElementById('root')
);
