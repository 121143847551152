export default function createStyles(config, className = '.button.button') {
  const {
    font,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight,
    background_color: backgroundColor,
    border_color: borderColor,
    border_thickness: borderThickness,
    border_radius: borderRadius,
    width,
    height,
    margin_top: marginTop,
    margin_bottom: marginBottom,
    margin_left: marginLeft,
    margin_right: marginRight,
    padding_top: paddingTop,
    padding_bottom: paddingBottom,
    padding_left: paddingLeft,
    padding_right: paddingRight,
    text_align: textAlign,
  } = config || {};

  let rules = '';
  if (textAlign?.length > 0) {
    rules += `text-align:${textAlign};`;
  }
  if (font?.length > 0) {
    rules += `font-family:${font};`;
  }
  if (fontSize?.length > 0) {
    rules += `font-size:${fontSize};`;
  }
  if (fontColor?.length > 0) {
    rules += `color:${fontColor};`;
  }
  if (fontWeight?.length > 0) {
    rules += `font-weight:${fontWeight};`;
  }
  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderThickness?.length > 0) {
    rules += `border-width:${borderThickness};`;
  }
  if (borderRadius?.length > 0) {
    rules += `border-radius:${borderRadius};`;
  }
  if (width?.length > 0) {
    rules += `width:${width};`;
  }
  if (height?.length > 0) {
    rules += `height:${height};`;
  }
  if (marginTop?.length > 0) {
    rules += `margin-top:${marginTop};`;
  }
  if (marginBottom?.length > 0) {
    rules += `margin-bottom:${marginBottom};`;
  }
  if (marginLeft?.length > 0) {
    rules += `margin-left:${marginLeft};`;
  }
  if (marginRight?.length > 0) {
    rules += `margin-right:${marginRight};`;
  }
  if (paddingTop?.length > 0) {
    rules += `padding-top:${paddingTop};`;
  }
  if (paddingBottom?.length > 0) {
    rules += `padding-bottom:${paddingBottom};`;
  }
  if (paddingLeft?.length > 0) {
    rules += `padding-left:${paddingLeft};`;
  }
  if (paddingRight?.length > 0) {
    rules += `padding-right:${paddingRight};`;
  }
  if (rules.length > 0) {
    return [`${className} {${rules}}`];
  }
  return [];
}
