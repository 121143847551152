export default function loadScript(url, head) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      resolve();
    };
    script.onerror = (error) => {
      reject(error);
    };
    script.src = url;
    if (head) {
      document.head.appendChild(script);
    } else document.body.appendChild(script);
  });
}
