import createLabelStyles from '../label/style-loader';

export default function createStyles(config, className = '.input-container.input-container') {
  const {
    font,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight,
    placeholder,
    background_color: backgroundColor,
    background_color_focus: backgroundColorFocus,
    border_color: borderColor,
    border_thickness: borderThickness,
    border_radius: borderRadius,
    left_adornment: adornment,
    padding_top: paddingTop,
    padding_bottom: paddingBottom,
    padding_left: paddingLeft,
    padding_right: paddingRight,
    width,
    height,
    margin_top: marginTop,
    margin_bottom: marginBottom,
    margin_left: marginLeft,
    margin_right: marginRight,
    underline_color: underlineColor,
    label,
    error,
  } = config || {};

  const {
    font: placeholderFont,
    font_size: placeholderFontSize,
    font_color: placeholderFontColor,
    font_weight: placeholderFontWeight,
  } = placeholder || {};

  const {
    border_color: errorBorderColor,
    border_thickness: errorBorderThickness,
    border_radius: errorBorderRadius,
  } = error;

  const styles = [];

  createLabelStyles(label, `${className} > .input-label`).forEach((style) => {
    styles.push(style);
  });

  createLabelStyles(error, `${className} > .input-helper-text`).forEach((style) => {
    styles.push(style);
  });

  createLabelStyles(adornment, `${className} > .input-box > .input-left-text`).forEach((style) => {
    styles.push(style);
  });

  let rules = '';

  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
    rules += `border-style:solid;`;
  }
  if (borderThickness?.length > 0) {
    rules += `border-width:${borderThickness};`;
    rules += `border-style:solid;`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }
  if (paddingTop?.length > 0) {
    rules += `padding-top:${paddingTop};`;
  }
  if (paddingBottom?.length > 0) {
    rules += `padding-bottom:${paddingBottom};`;
  }
  if (paddingLeft?.length > 0) {
    rules += `padding-left:${paddingLeft};`;
  }
  if (paddingRight?.length > 0) {
    rules += `padding-right:${paddingRight};`;
  }
  if (width?.length > 0) {
    rules += `width:${width};`;
  }
  if (height?.length > 0) {
    rules += `height:${height};`;
  }
  if (marginTop?.length > 0) {
    rules += `margin-top:${marginTop};`;
  }
  if (marginBottom?.length > 0) {
    rules += `margin-bottom:${marginBottom};`;
  }
  if (marginLeft?.length > 0) {
    rules += `margin-left:${marginLeft};`;
  }
  if (marginRight?.length > 0) {
    rules += `margin-right:${marginRight};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .input-box {${rules}}`);
  }

  if (backgroundColorFocus) {
    styles.push(`${className} > .input-box-focused {background-color: ${backgroundColorFocus}}`);
  }

  if (underlineColor) {
    styles.push(`${className} > .input-box-focused {border-bottom-color: ${underlineColor}}`);
  }

  rules = '';
  if (font?.length > 0) {
    rules += `font-family:${font};`;
  }
  if (fontSize?.length > 0) {
    rules += `font-size:${fontSize};`;
  }
  if (fontColor?.length > 0) {
    rules += `color:${fontColor};`;
  }
  if (fontWeight?.length > 0) {
    rules += `font-weight:${fontWeight};`;
  }
  if (rules.length > 0) {
    styles.push(`${className} > .input-box > .input-field {${rules}}`);
  }

  rules = '';

  if (placeholderFont?.length > 0) {
    rules += `font-family:${placeholderFont};`;
  }
  if (placeholderFontSize?.length > 0) {
    rules += `font-size:${placeholderFontSize};`;
  }
  if (placeholderFontColor?.length > 0) {
    rules += `color:${placeholderFontColor};`;
  }
  if (placeholderFontWeight?.length > 0) {
    rules += `font-weight:${placeholderFontWeight};`;
  }
  if (rules.length > 0) {
    styles.push(
      `${className} > .input-box > .input-field::-webkit-input-placeholder, ${className} > .input-box > .input-field::placeholder {${rules}}`
    );
  }

  rules = '';
  if (errorBorderColor?.length > 0) {
    rules += `border-color:${errorBorderColor};`;
    rules += `border-style:solid;`;
  }
  if (errorBorderThickness?.length > 0) {
    rules += `border-width:${errorBorderThickness};`;
    rules += `border-style:solid;`;
  }
  if (errorBorderRadius) {
    rules += `border-radius:${errorBorderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`${className}.error > .input-box.input-box {${rules}}`);
  }

  return styles;
}
