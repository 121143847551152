export default function createStyles(config, className = '.label.label') {
  const {
    font,
    font_size: fontSize,
    font_color: fontColor,
    font_weight: fontWeight,
    padding_top: paddingTop,
    padding_bottom: paddingBottom,
    link_color: linkColor,
    line_height: lineHeight,
  } = config || {};

  const styles = [];
  let rules = '';
  if (font?.length > 0) {
    rules += `font-family:${font};`;
  }
  if (fontSize?.length > 0) {
    rules += `font-size:${fontSize};`;
  }
  if (fontColor?.length > 0) {
    rules += `color:${fontColor};`;
  }
  if (fontWeight?.length > 0) {
    rules += `font-weight:${fontWeight};`;
  }
  if (lineHeight?.length > 0) {
    rules += `line-height:${lineHeight};`;
  }
  if (paddingTop?.length > 0) {
    rules += `padding-top:${paddingTop};`;
  }
  if (paddingBottom?.length > 0) {
    rules += `padding-bottom:${paddingBottom};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} {${rules}}`);
  }

  if (linkColor || fontColor) {
    const {
      unvisited = fontColor,
      visited = fontColor,
      hover = fontColor,
      active = fontColor,
    } = linkColor || {};

    if (unvisited?.length > 0) {
      styles.push(`${className} > a:link { color: ${unvisited}}`);
    }

    if (visited?.length > 0) {
      styles.push(`${className} > a:visited { color: ${visited}}`);
    }

    if (hover?.length > 0) {
      styles.push(`${className} > a:hover { color: ${hover}}`);
    }

    if (active?.length > 0) {
      styles.push(`${className} > a:active { color: ${active}}`);
    }
  }
  return styles;
}
