import '@babel/polyfill';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocaleEn from './resources/locales/en.json';
import AlertProvider from './util/alert/alert-provider';
import loadScript from './util/load-script';
import deepMerge from './util/deep-merge';
import createButtonStyle from './components/button/style-loader';
import createLabelStyle from './components/label/style-loader';
import createInputStyle from './components/input/style-loader';
import createCheckBoxStyle from './components/checkbox/style-loader';
import createSelectStyle from './components/select/style-loader';
import createFacebookStyles from './components/facebook/style-loader';
import createGoogleStyles from './components/google/style-loader';
import createOutsideStyles from './components/outside/style-loader';
import createAgeGageStyle from './features/age-cage/style-loader';
import createClaimStyle from './features/claim/style-loader';
import createLoginStyle from './features/login/style-loader';
import createErrorStyle from './features/error/style-loader';
import createUnsubscribeStyle from './features/unsubscribe/style-loader';
import createMobileOnlyStyles from './components/mobile-only/style-loader';

export default class App extends React.Component {
  constructor() {
    super();
    // Setup state
    this.state = {};
  }

  componentDidMount() {
    const { path } = this.props;
    fetch(`${path}public/config/config.json`, { method: 'GET', cache: 'no-store' })
      .then((result) => result.json())
      .then((config) => {
        const {
          global_config_path: globalConfigPath,
          locales,
          default_language: defaultLanguage,
        } = config;
        if (locales !== undefined && (locales === null || Array.isArray(locales))) {
          // eslint-disable-next-line no-param-reassign
          config.locales = {};
        }
        if (
          defaultLanguage === null ||
          (Array.isArray(defaultLanguage) && defaultLanguage.length === 0) ||
          defaultLanguage === ''
        ) {
          // eslint-disable-next-line no-param-reassign
          delete config.default_language;
        }
        if (globalConfigPath) {
          return fetch(`${globalConfigPath}`, { method: 'GET', cache: 'no-store' })
            .then((result) => result.json())
            .catch(() => ({}))
            .then((globalConfig) => {
              return deepMerge(globalConfig, config);
            });
        }
        return config;
      })
      .then((config) => {
        const { beta } = config;
        if (beta) {
          return Promise.all([
            loadScript('https://cdn-beta.smartmedialabs.io/v/js/VlabsUser.js'),
            loadScript('https://cdn-beta.smartmedialabs.io/v/js/VlabsUnsubscribe.js'),
          ]).then(() => config);
        }
        return Promise.all([
          loadScript('https://cdn.smartmedialabs.io/v/js/VlabsUser.js'),
          loadScript('https://cdn.smartmedialabs.io/v/js/VlabsUnsubscribe.js'),
        ]).then(() => config);
      })
      .then((config) => {
        // generate style sheet from config
        const { style, age, claim, login, error, unsubscribe } = config;
        const {
          external,
          button,
          label,
          input,
          checkbox,
          select,
          facebook,
          google,
          outside,
          mobile_only: mobileOnly,
        } = style;
        const sheet = document.createElement('style');
        document.body.appendChild(sheet);
        const styleSheet = sheet.sheet;
        style.fonts?.forEach((font) => {
          styleSheet.insertRule(
            `@font-face {font-family: ${font?.family};src: url(${font.src});}`,
            styleSheet.cssRules.length
          );
        });
        if (style.background_color?.length > 0) {
          styleSheet.insertRule(
            `html {background-color: ${style.background_color};}`,
            styleSheet.cssRules.length
          );
        }
        createFacebookStyles(facebook).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });
        createGoogleStyles(google).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });
        createOutsideStyles(outside).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createMobileOnlyStyles(mobileOnly).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createButtonStyle(button).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createLabelStyle(label).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createCheckBoxStyle(checkbox).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createSelectStyle(select).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        createInputStyle(input).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        if (age) {
          createAgeGageStyle(age.style).forEach((rule) => {
            styleSheet.insertRule(rule, styleSheet.cssRules.length);
          });
        }
        createClaimStyle(claim?.style).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });
        createLoginStyle(login?.style).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });
        createUnsubscribeStyle(unsubscribe?.style).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });
        createErrorStyle(error?.style).forEach((rule) => {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        });

        if (external?.length > 0) {
          return Promise.all(
            external.map((stylePath) => {
              return new Promise((resolve) => {
                const stylesheet = document.createElement('link');
                stylesheet.rel = 'stylesheet';
                stylesheet.type = 'text/css';
                stylesheet.href = stylePath;
                const timeout = setTimeout(() => {
                  resolve(config);
                  stylesheet.onload = null;
                  stylesheet.onerror = null;
                }, 3000);
                stylesheet.onload = () => {
                  clearTimeout(timeout);
                  stylesheet.onload = null;
                  stylesheet.onerror = null;
                  resolve();
                };
                stylesheet.onerror = (loadError) => {
                  console.error(loadError);
                  clearTimeout(timeout);
                  stylesheet.onload = null;
                  stylesheet.onerror = null;
                  resolve();
                };
                document.getElementsByTagName('head')[0].prepend(stylesheet);
              });
            })
          ).then(() => config);
        }
        return Promise.resolve(config);
      })
      .then((config) => {
        this.config = config;
        const resources = {};
        const {
          locales = {},
          disable_built_in_language: disableBuiltInLanguage,
          supported_locales: supportedLocales,
          default_language: defaultLanguage,
        } = config;
        if (!disableBuiltInLanguage) {
          resources.en = { translation: LocaleEn };
        }

        let defaultLang = defaultLanguage;
        if (Array.isArray(defaultLanguage)) {
          [defaultLang] = defaultLanguage;
        }
        if (supportedLocales.indexOf(defaultLang) === -1) {
          // eslint-disable-next-line no-param-reassign
          config.default_language = supportedLocales;
        }
        return Promise.all(
          Object.keys(locales).map((name) => {
            if (supportedLocales && supportedLocales.indexOf(name) === -1) {
              return Promise.resolve();
            }
            const { external, overrides } = locales[name];

            const load = () => {
              const { translation } = resources[name] ?? {};
              const result = deepMerge(translation, overrides);
              resources[name] = { translation: result };
            };
            if (external?.length > 0) {
              return fetch(external)
                .then((result) => result.json())
                .then((locale) => {
                  resources[name] = { translation: locale };
                })
                .then(() => {
                  load();
                })
                .catch(() => {
                  return {};
                });
            }
            return Promise.resolve(load());
          })
        ).then(() => {
          i18n
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
              debug: true,
              fallbackLng:
                this.config.default_language?.length > 0 ? this.config.default_language : 'en',
              detection: {
                order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
              },
              resources,
            });
          this.setState({ config: this.config });
        });
      })
      .catch((error) => {
        console.log(error);
        // No config
      });
  }

  render() {
    const { config } = this.state;
    const { children } = this.props;
    return <AlertProvider>{config && children?.(config)}</AlertProvider>;
  }
}
