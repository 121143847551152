import createLabelStyles from '../../components/label/style-loader';
import createButtonStyles from '../../components/button/style-loader';
import createInputStyles from '../../components/input/style-loader';
import createCheckBoxStyles from '../../components/checkbox/style-loader';
import createSelectStyles from '../../components/select/style-loader';
import createFacebookStyles from '../../components/facebook/style-loader';
import createGoogleStyles from '../../components/google/style-loader';
import createOutsideStyles from '../../components/outside/style-loader';

export default function createStyles(config) {
  const {
    background_color: backgroundColor,
    min_width: minWidth,
    max_width: maxWidth,
    landing,
    link,
    expired,
  } = config || {};
  const {
    background_image: landingImage,
    footer: landingFooter,
    button,
    input,
    custom_input: customInput,
    select,
    checkbox,
    label: landingLabel,
    facebook,
    google,
    outside,
  } = landing;
  const {
    background_image: linkBackgroundImage,
    link_image: linkImage,
    label: linkLabel,
    footer: linkFooter,
  } = link;
  const { background_image: expiredImage, label: expiredLabel, footer: expiredFooter } = expired;
  const styles = [];

  createFacebookStyles(facebook, `.claim-landing-facebook`).forEach((style) => {
    styles.push(style);
  });

  createGoogleStyles(google, `.claim-landing-google`).forEach((style) => {
    styles.push(style);
  });
  createOutsideStyles(outside, `.claim-landing-outside`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(landingLabel, `.claim-landing-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(landingFooter, `.claim-landing-footer`).forEach((style) => {
    styles.push(style);
  });
  createButtonStyles(button, `.claim-landing-button`).forEach((style) => {
    styles.push(style);
  });
  createInputStyles(input, `.claim-landing-input`).forEach((style) => {
    styles.push(style);
  });

  if (customInput) {
    customInput.forEach((customInputStyles, index) => {
      createInputStyles(customInputStyles, `.claim-landing-input-custom${index}`).forEach(
        (style) => {
          styles.push(style);
        }
      );
    });
  }
  createCheckBoxStyles(checkbox, `.claim-landing-checkbox`).forEach((style) => {
    styles.push(style);
  });
  createSelectStyles(select, `.claim-landing-select-additional`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(linkLabel, `.claim-link-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(linkFooter, `.claim-link-footer`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(expiredLabel, `.claim-expired-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(expiredFooter, `.claim-expired-footer`).forEach((style) => {
    styles.push(style);
  });

  if (backgroundColor?.length > 0) {
    styles.push(`body {background-color: ${backgroundColor};}`);
  }
  if (minWidth?.length > 0) {
    styles.push(
      `.claim-landing-inner-container, .claim-link-inner-container, .claim-expired-inner-container {min-width: ${minWidth};}`
    );
  }

  if (maxWidth?.length > 0) {
    styles.push(
      `.claim-landing-inner-container, .claim-link-inner-container, .claim-expired-inner-container {max-width: ${maxWidth};}`
    );
  }

  if (landingImage) {
    let rules = '';
    if (landingImage?.min_width?.length > 0) {
      rules += `min-width: ${landingImage?.min_width}; `;
    }
    if (landingImage?.max_width?.length > 0) {
      rules += `max-width: ${landingImage?.max_width};`;
    }
    if (landingImage?.height?.length > 0) {
      rules += `height: ${landingImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.claim-landing-background-image-container { ${rules} }`);
    }
  }

  if (linkBackgroundImage) {
    let rules = '';
    if (linkBackgroundImage?.min_width?.length > 0) {
      rules += `min-width: ${linkBackgroundImage?.min_width}; `;
    }
    if (linkBackgroundImage?.max_width?.length > 0) {
      rules += `max-width: ${linkBackgroundImage?.max_width};`;
    }
    if (linkBackgroundImage?.height?.length > 0) {
      rules += `height: ${linkBackgroundImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.claim-link-background-image-container { ${rules} }`);
    }
  }
  if (linkImage) {
    if (linkImage?.src?.length > 0) {
      styles.push(`.claim-link-image { content: url(${linkImage?.src}); }`);
    }
    let rules = '';
    if (linkImage?.width?.length > 0) {
      rules += `width: ${linkImage?.width}; `;
    }
    if (linkImage?.height?.length > 0) {
      rules += `height: ${linkImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.claim-link-image-container { ${rules} }`);
    }
  }

  if (expiredImage) {
    let rules = '';
    if (expiredImage?.min_width?.length > 0) {
      rules += `min-width: ${expiredImage?.min_width}; `;
    }
    if (expiredImage?.max_width?.length > 0) {
      rules += `max-width: ${expiredImage?.max_width};`;
    }
    if (expiredImage?.height?.length > 0) {
      rules += `height: ${expiredImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.claim-expired-background-image-container { ${rules} }`);
    }
  }

  return styles;
}
